import React, {useState} from "react";
import {
  confirmationAlertDeleteFundCategory,
  useDeleteFundCategory,
  useFundCategories
} from "../../../query-hooks/fund-query-hooks/useFund";
import {useAuth} from "../../../Hooks/useAuth";
import {useCategories} from "../../../query-hooks/category-query-hooks/useCategories";
import {Col, Row, Table} from "react-bootstrap";
import styles from "../FundDetail.module.scss";
import {Link} from "react-router-dom";
import EmptyMessageComponent from "../../../Components/EmptyMessageComponent";
import {ErrorLoading, Spinner} from "../../../Helpers/Helpers";
import AddFundCategoryForm from "./AddFundCategoryForm";
import {AiOutlineDelete} from "react-icons/ai";
import Pagination from "../../../Components/Pagination";

export const FundCategoryTableView = ({fundId}) => {
  const [categorySelected, setCategorySelected] = useState()
  const mutation = useDeleteFundCategory()
  const thisFundCategories = useFundCategories(fundId)
  const [currentItems, setCurrentItems] = useState();
  const {darkmode} = useAuth()
  const getCurrentItems = (updatedItems) => setCurrentItems(updatedItems)
  const closeModal = () => setCategorySelected()
  const allCategories = useCategories("")

  if (allCategories.isSuccess && allCategories.data.items.length < 1) {
    return <>
      <Row className="mb-4">
        <Col lg={12} className={styles.addContainer}>Add a Category <Link className={styles.addIcon}
                                                                          to="/main/categories/create-category">+</Link></Col>
      </Row>
      <EmptyMessageComponent message="No Categories Added"/>
    </>
  }

  return <>
    <Row className="mb-4">
      <Col lg={12} className={styles.addContainer}>Add a Category <Link className={styles.addIcon}
                                                                        to="/main/categories/create-category">+</Link></Col>
    </Row>
    {allCategories.isLoading && <Spinner/>}
    {allCategories.isError && <ErrorLoading/>}
    <select className={darkmode ? styles.selectInputDark : styles.selectInput}
            onChange={e => setCategorySelected(allCategories.data.items.find(cat => cat.id === e.target.value))}>
      <option>Select a Category</option>
      {allCategories.isSuccess && allCategories.data.items.map(category => (
        <option key={category.id} value={category.id}>{category.name}</option>
      ))}
    </select>

    {categorySelected &&
      <AddFundCategoryForm fundId={fundId} categorySelected={categorySelected} closeModal={closeModal}/>}
    <Table responsive borderless={true}>
      <thead>
      <tr className={styles.theader}>
        <th></th>
        <th>Min Percentage</th>
        <th>Max. Percentage</th>
        <th></th>
      </tr>
      </thead>
      {currentItems && currentItems.map((category) => (
        <tbody key={category.categoryId}>
        <tr className={darkmode ? styles.tableStripsDark : styles.tableStrips}>
          <td style={{textAlign: "left"}}>{category.name}</td>
          <td>{category.minPercentage}</td>
          <td>{category.maxPercentage}</td>
          <td className={styles.tooltip}
              onClick={(e) => confirmationAlertDeleteFundCategory(e, category.categoryId, fundId, mutation)}>
            <AiOutlineDelete className={styles.icons}/>
            <span className={styles.tooltiptext}>Delete</span>
          </td>
        </tr>
        </tbody>
      ))}
    </Table>
    {thisFundCategories.isSuccess &&
      <Pagination items={thisFundCategories.data} getCurrentItems={getCurrentItems} itemsPerPage={10}/>}
    {thisFundCategories.isLoading && <Spinner/>} {thisFundCategories.isError && <ErrorLoading/>}
  </>
}
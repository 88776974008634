import React, {useState} from 'react'
import {Tab, Tabs} from 'react-bootstrap'
import CollapsibleComponent from '../../../Components/CollapsibleComponent'
import {FundCategoryHoldingsView} from "./FundCategoryHoldingsView";
import {FundCategoryTableView} from "./FundCategoryTableView";


const FundCategoryDashboardCard = ({ fundId }) => {
    const [categoryTabKey, setCategoryTabKey] = useState("holdingsView");

  function getTabStyles(key) {
    return {
      style: {
        color: '#6D9E93',
        border:'none',
        backgroundColor: categoryTabKey === key ? '#5D8E8322': '#FFFFFF00'
      }
    };
  }
    return (
      <CollapsibleComponent title="Fund Categories" contentClasses={'px-0 pt-0'}>
        <Tabs fill id="fund-category-tabs" activeKey={categoryTabKey} onSelect={(k) => setCategoryTabKey(k)} className="mb-3 mt-0">
          <Tab eventKey="holdingsView" title="Holdings" tabAttrs={getTabStyles('holdingsView')}>
            <div className={'px-4'}>
              <FundCategoryHoldingsView fundId={fundId}/>
            </div>
          </Tab>
          <Tab eventKey="categoriesView" title="Categories" tabAttrs={getTabStyles('categoriesView')}>
            <div className={'px-4'}>
              <FundCategoryTableView fundId={fundId}/>
            </div>
          </Tab>
        </Tabs>
      </CollapsibleComponent>
    )
}
export default FundCategoryDashboardCard
import { useFundCategoryWeights } from "../../../query-hooks/fund-query-hooks/useFund";
import React, { useEffect } from "react";
import { Spinner } from "../../../Helpers/Helpers";
import styles from "./AddFundCategoryForm.module.scss";
import { GroupTree } from "./TreeviewComponents/GroupTree";
import {
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";

export interface FundCategoryHoldingsViewParams {
  fundId: string;
}

export enum WeightDisplayMode {
  category,
  total,
}

export interface WeightFormValues {
  allHoldings: boolean;
  groups: object[];
  fundId: string;
  categoryEditId: string;
  displayMode: WeightDisplayMode;
}

export function FundCategoryHoldingsView({
  fundId,
}: FundCategoryHoldingsViewParams) {
  const methods = useForm<WeightFormValues>({
    defaultValues: {
      allHoldings: false,
      groups: [],
      categoryEditId: "",
      fundId: fundId,
      displayMode: WeightDisplayMode.total,
    },
  });
  const { register, getValues, watch } = methods;
  const { fields: groups } = useFieldArray({
    control: methods.control,
    name: "groups",
  });

  const groupsApi = useFundCategoryWeights(fundId, watch("allHoldings"));

  useEffect(() => {
    if (groupsApi.isSuccess) {
      methods.reset({
        fundId: fundId,
        groups: groupsApi.data,
        categoryEditId: "",
        allHoldings: getValues("allHoldings"),
        displayMode: getValues("displayMode"),
      });
    }
  }, [groupsApi.data, fundId, groupsApi.isSuccess, methods]);

  return (
    <div>
      <div className={"h-full overflow-y-scroll"} style={{ maxHeight: "35rem" }}>
        <label className={styles.checkbox} style={{ margin: 0 }}>
          <input
            type="checkbox"
            {...register("allHoldings")}
            style={{ marginTop: "0.25rem" }}
          ></input>
          <span style={{ fontSize: "12px" }}>All holdings</span>
        </label>
        {groups == null && <Spinner></Spinner>}
        {groups != null && (
          <FormProvider {...methods}>
            {groups.map((_, index) => (
              <GroupTree
                key={`group-${index}`}
                fundId={fundId}
                groupIndex={index}
              ></GroupTree>
            ))}
          </FormProvider>
        )}
      </div>
      {
        // bug where algo shows up twice?
        // 5. not only percentages but also usd value? btc value? eur value?
        // remove all submit methods, replace with single replaceCategoryAndWeightsRequest()
        // merge Backend, remove interfaces and use those from the npm package
        // try out useReducer for state and function passing
        // unify input and output properties, so I don't have to use a mapper function
      }
    </div>
  );
}

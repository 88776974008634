import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap"
import styles from "./FundDetail.module.scss"
import { previousBookingPeriod, bookingPerioddateFormat, numberEditor } from "../../Helpers/Helpers";
import { useCrypto } from "../../query-hooks/crypto-query-hooks/useCryptos";
import { IoIosArrowDropdownCircle  } from "react-icons/io";
import { useFetchNavByPeriod } from "../../query-hooks/fund-query-hooks/useFund";

const FundMetadata = ({ fund, fundId, bookingPeriod }) => {
    const crypto = useCrypto(fund.data.primaryCryptoCurrencyId)
    const [ expandContainer, setExpandContainer ] = useState(false)
    const periodNav = useFetchNavByPeriod(fundId, bookingPeriod)
    const [ navData, setNavData ] = useState()
    
    useEffect(() => {
        if (periodNav.isSuccess) {
            if (periodNav.data.length > 0) {
                return setNavData(periodNav.data[0])
            }
            setNavData(fund.data.nav)
        }
    }, [periodNav.data])

    if (navData) {
    
        return (
            <Col xs={12}>
                <div className={styles.metadataCard}>
                    <Row>
                        <Col xl={3}>
                            <h5 className={styles.headerData}>{ numberEditor(navData.nav) }</h5>
                            <h5 className={styles.headerTitle}>NAV Period {bookingPerioddateFormat(navData.bookingPeriod)} </h5>
                        </Col>
                        <Col xl={3}>
                            <h5 className={styles.headerData}>{numberEditor(navData.hwm)}</h5>
                            <h5 className={styles.headerTitle}>HWM</h5>
                        </Col>
                        <Col xl={3}>
                            <h5 className={styles.headerData}>{numberEditor(navData.totalShares)}</h5>
                            <h5 className={styles.headerTitle}>Total Shares</h5>
                        </Col>
                        <Col xl={3}>
                            <h5 className={styles.headerData}>{numberEditor(navData.totalValue)}</h5>
                            <h5 className={styles.headerTitle}>Total Value</h5>
                        </Col>
                    </Row>
                    {
                        expandContainer && 
                        <>
                            <Row>
                                <Col xl={3}>
                                    <h5 className={styles.headerData}>{fund.data.reportingCurrencyCode}</h5>
                                    <h5 className={styles.headerTitle}>Reporting Currency</h5>
                                </Col>
                                <Col xl={3}>
                                    <h5 className={styles.headerData}>{crypto.isSuccess && crypto.data.symbol}</h5>
                                    <h5 className={styles.headerTitle}>Primary CryptoCurrency</h5>
                                </Col>
                                <Col xl={3}>
                                    <h5 className={styles.headerData}>{fund.data.maxVolume}</h5>
                                    <h5 className={styles.headerTitle}>Max Volume</h5>
                                </Col>
                                <Col xl={3}>
                                    <h5 className={styles.headerData} >{fund.data.hwmResetPolicy}</h5>
                                    <h5 className={styles.headerTitle}>Reset Policy</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={3}>
                                    <h5 className={styles.headerData}>{fund.data.managementFeeFrequency} per year</h5>
                                    <h5 className={styles.headerTitle}>Admin. Fee Frequency</h5>
                                </Col>
                                <Col xl={3}>
                                    <h5 className={styles.headerData}>{fund.data.performanceFee}%</h5>
                                    <h5 className={styles.headerTitle}>Performance Fee</h5>
                                </Col>
                                <Col xl={3}>
                                    <h5 className={styles.headerData}>{fund.data.managementFee}%</h5>
                                    <h5 className={styles.headerTitle}>Management Fee</h5>
                                </Col>
                                <Col xl={3}>
                                    <h5 className={styles.headerData}>{fund.data.hwmResetDate?.slice(0, 10) || "-"}</h5>
                                    <h5 className={styles.headerTitle}>Reset Date</h5>
                                </Col>
                            </Row>
                        </>
                    }
                    <IoIosArrowDropdownCircle className={styles.metadataCardToggleIcon} onClick={() => setExpandContainer(!expandContainer)} style={expandContainer ? {transform: "rotate(180deg)"} : {}} />
                </div>
            </Col>
        );
    }
};
export default FundMetadata;

import styles from "./AllBenchmarks.module.scss";
import React, {useEffect} from "react";
import {useImportBenchmarkData} from "../../../query-hooks/benchmark-query-hooks/useBenchmark";
import {simpleSuccessfulMessage} from "../../../Helpers/Helpers";

interface BenchmarkImportButtonsParams {
  benchmarkId: string;
  setImportCandlesModal: (value: boolean) => void;
}

export function BenchmarkImportButtons({benchmarkId, setImportCandlesModal}: BenchmarkImportButtonsParams) {
  const importBenchmarkData = useImportBenchmarkData()

  useEffect(() => {
    importBenchmarkData.isSuccess && simpleSuccessfulMessage("Import Successful")
  }, [importBenchmarkData.isSuccess])

  return <>
    <div className={"d-flex flex-row justify-content-center"}>
      <button className={styles.editButton}
              onClick={() => importBenchmarkData.mutate({benchmarkId})}>Import Data from API
      </button>
      <button className={styles.editButton}
              style={{"maxHeight": "3em"}}
              onClick={() => setImportCandlesModal(true)}>Import Data from Excel Sheets
      </button>
    </div>
  </>
}
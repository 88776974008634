import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useFund, useRollbackPeriod, useClosePeriod, confirmationAlertRollback, confirmationClosePeriod, useCreateNAV, useHoldingBookingPeriods, getMonthString, useCloseDate, useRecalculatePeriod, confirmationRecalculateActivePeriod } from '../../query-hooks/fund-query-hooks/useFund'
import FundCategoryDashboardCard from './FundsCategories/FundCategoryDashboardCard'
import FundLayerDashboardCard from './FundsLayers/FundLayerDashboardCard'
import FundHoldingDashboardCard from './FundsHoldings/FundHoldingDashboardCard'
import FundBankAccountDashboardCard from './FundsBankAccounts/FundBankAccountDashboardCard'
import FundTransferDashboardCard from "../Transfers/FundTransferDashboardCard"
import styles from "./FundDetail.module.scss"
import { useAuth } from '../../Hooks/useAuth'
import { rollbackSuccesfulMessage, closePeriodSuccesfulMessage, simpleSuccessfulMessage, ErrorLoading, Spinner, bookingPerioddateFormat } from '../../Helpers/Helpers'
import FundMetadata from './FundMetadata'
import CollapsibleComponent from '../../Components/CollapsibleComponent'
import LoadingMutation from '../../Components/LoadingMutation'
import ClassDashboardComponent from './Classes/ClassDashboardComponent'
import ImportNavModal from './ImportNavModal'
import ComparerModal from './ComparerModal'
import { MdCompareArrows, MdOutlineVerticalAlignBottom, MdOutlineCreateNewFolder, MdUndo, MdHighlightOff, MdKeyboardArrowDown, MdCalendarMonth, MdCalculate } from "react-icons/md";
import { useCheckRole } from '../../query-hooks/user-query-hooks/useUser'
import FundExchangeAccountDashboardCard from "./FundsExchangeAccounts/FundExchangeAccountDashboardCard";

const FundDetail = () => {
    const { fundId } = useParams()
    const fund = useFund(fundId)
    const rollbackClosePeriod = useRollbackPeriod()
    const closePeriod = useClosePeriod()
    const { darkmode } = useAuth()
    const [ bookingPeriod, setBookingPeriod ] = useState()
    const [ selectedFundClassId, setSelectedFundClassId ] = useState()
    const [ importNavWindow, setImportNavWindow ] = useState(false)
    const [ displayComparerModal, setDisplayComparerModal ] = useState(false)
    const bookingPeriods = useHoldingBookingPeriods(fundId)
    const isAdmin = useCheckRole("ADMIN")
    const closeDate = useCloseDate(fundId, bookingPeriod)

    // Set active booking period as default
    useEffect(() => {
        bookingPeriods.isSuccess && setBookingPeriod(bookingPeriods.data[0])
    }, [ bookingPeriods.data ])

    // Set if selected booking period is the active booking period. Logic to display recalculate active booking period if this is selected. Otherwise, recalculate function to closed period
    const [ activeBookingPeriod, setActiveBookingPeriod ] = useState(false)
    useEffect(() => { 
        if (bookingPeriod && bookingPeriods.isSuccess) {
            bookingPeriods.data[0] === bookingPeriod ? setActiveBookingPeriod(true) : setActiveBookingPeriod(false)
        } 
    }, [bookingPeriod, bookingPeriods])
    const recalculatePeriod = useRecalculatePeriod()
    useEffect(() => { recalculatePeriod.isSuccess && simpleSuccessfulMessage("Active Period has been recalculated succesfully")}, [recalculatePeriod.isSuccess])
    
    const createNAV = useCreateNAV()

    // Success Messages
    useEffect(() => {rollbackClosePeriod.isSuccess && rollbackSuccesfulMessage() }, [rollbackClosePeriod]) 
    useEffect(() => {closePeriod.isSuccess && closePeriodSuccesfulMessage(bookingPeriod) }, [closePeriod])
    useEffect(() => { createNAV.isLoading && simpleSuccessfulMessage("NAV is being created. Please wait for confirmation")}, [createNAV.isLoading])
    
    if (fund.isSuccess) {
        return (
            <>
                <Row>
                    { closePeriod.isLoading && <LoadingMutation title={`Closing Period ${bookingPerioddateFormat(bookingPeriod)} in process...`} /> }
                    { importNavWindow && <ImportNavModal setImportNavWindow={setImportNavWindow} fundId={fundId} /> }
                    { displayComparerModal && <ComparerModal setDisplayComparerModal={setDisplayComparerModal} fundId={fundId} bookingPeriod={bookingPeriod} selectedFundClassId={selectedFundClassId} /> }
                    <Row>
                        <Col xl={4}>
                            <span className={styles.mainFundTitle} style={{color:"#A5C6AC"}}>{ fund.data.fundName }</span>
                            {   fund.data.fundName.toLowerCase().includes("fund") === false && <span className={styles.secondaryFundTitle}> Fund</span> }
                            {   bookingPeriod && <><span className={styles.bookingPeriodtitle}> Booking Period <span style={{color:"#A5C6AC"}}>{ bookingPerioddateFormat(bookingPeriod) }</span></span></> }
                            {   bookingPeriods.isSuccess &&
                                    <select onClick={e => setBookingPeriod(e.target.value)} className={darkmode ? styles.selectInputDark : styles.selectInput} defaultValue={bookingPeriod}>
                                        { bookingPeriods.data.map(period => ( <option key={period} value={period}>{ getMonthString(period)}</option> )) }
                                    </select>
                            }
                        </Col>
                        <Col xl={6}></Col>
                        <Col xl={2} xs={12}>
                            <div className={styles.dropdown} >
                                <button>Options <MdKeyboardArrowDown className={styles.dropdownIcon} /></button>
                                <div className={styles.dropdownContent}>
                                    <p onClick={() => setDisplayComparerModal(true)}><MdCompareArrows className={styles.dropdownIcon} />Comparer</p>        
                                    <p onClick={() => setImportNavWindow(true)}><MdOutlineVerticalAlignBottom className={styles.dropdownIcon} />Import NAV</p>        
                                    <p onClick={() => createNAV.mutate({fundId, payload: { period: bookingPeriod, recalculate: true}})}><MdOutlineCreateNewFolder className={styles.dropdownIcon} />Create NAV</p>        
                                    <p onClick={e => confirmationAlertRollback(e, fundId, rollbackClosePeriod, bookingPeriods.data)}><MdUndo className={styles.dropdownIcon} />Rollback Period</p>        
                                    {/* // Logic to display Recalculate button, if the selected boooking period, trigger recalc function */}
                                    { (isAdmin.isSuccess && isAdmin.data === true) && 
                                        activeBookingPeriod ? 
                                        <p onClick={e => confirmationRecalculateActivePeriod(e, fundId, recalculatePeriod)}><MdCalculate  className={styles.dropdownIcon} />Recalculate Active Period</p> : 
                                        <p onClick={e => confirmationClosePeriod(e, fundId, bookingPeriod, closePeriod, true, null, null)}><MdCalendarMonth  className={styles.dropdownIcon} />Recalculate Selected Period</p> 
                                    }
                                    { fund.data.closeHistoryButton && <p onClick={e => confirmationClosePeriod(e, fundId, bookingPeriod, closePeriod, false, fund.data.managementFeeFrequency, closeDate.isSuccess && closeDate.data)}><MdHighlightOff className={styles.dropdownIcon} />Close Period</p> }
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <FundMetadata fund={fund} fundId={fundId} bookingPeriod={bookingPeriod} />
                </Row>
                <ClassDashboardComponent fundId={fundId} bookingPeriod={bookingPeriod} selectedFundClassId={selectedFundClassId} setSelectedFundClassId={setSelectedFundClassId} />
                <Row>
                    <Col xl={5}>
                        <CollapsibleComponent title={ selectedFundClassId ? `Transfers: ${fund.data.classes.find(fundClass => fundClass.id === selectedFundClassId).className}` : "Transfers"} fundsCards bookingPeriod={bookingPeriod}>
                            <div className={styles.scrollableContainer} style={{height:"50rem"}}>
                                <FundTransferDashboardCard fundId={fundId} bookingPeriod={bookingPeriod} selectedFundClassId={selectedFundClassId} />
                            </div>
                        </CollapsibleComponent>
                    </Col>
                    <Col xl={7}>
                        <CollapsibleComponent title={ selectedFundClassId ? `Holdings: ${fund.data.classes.find(fundClass => fundClass.id === selectedFundClassId).className}` : "Holdings"} fundsCards selectedFundClassId={selectedFundClassId} bookingPeriod={bookingPeriod}>
                            <div className={styles.scrollableContainer} style={{ height: "50rem" }}>
                                <FundHoldingDashboardCard reportingCurrencyCode={fund.data.reportingCurrencyCode} fundId={fundId} bookingPeriod={bookingPeriod} selectedFundClassId={selectedFundClassId} /> 
                            </div>
                        </CollapsibleComponent>
                    </Col>
                </Row>
                <Row>
                    <Col xl={4} md={12} xs={12}>
                        <div className={ darkmode ? styles.cardModelDark : styles.cardModel } style={{height: "45rem"}}>
                            <FundLayerDashboardCard fundId={fundId} fund={fund} />  
                        </div>
                    </Col>
                    <Col xl={4} md={12} xs={12}>
                        <div className={darkmode ? styles.cardModelDark : styles.cardModel} style={{height: "45rem"}}>
                            <FundCategoryDashboardCard fundId={fundId} />
                        </div>
                    </Col>
                    <Col xl={4} md={12} xs={12}>
                        <div className={ darkmode ? styles.cardModelDark : styles.cardModel } style={{height: "45rem"}}>
                            <FundExchangeAccountDashboardCard fund={fund} fundId={fundId} />
                        </div>
                    </Col>
                    <Col xl={4} md={12} xs={12}>
                        <div className={ darkmode ? styles.cardModelDark : styles.cardModel } style={{height: "45rem"}}>
                            <FundBankAccountDashboardCard fund={fund} fundId={fundId} />
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
    {fund.isError && <ErrorLoading error={fund.error} />}
    {fund.isLoading && <Spinner />}
}
export default FundDetail
import styles from "./ChartsDashboard.module.scss"
import NavCharts from "./Charts/NavCharts";
import { useAuth } from "../../Hooks/useAuth"
import LayersChart from "./Charts/LayersChart";
import TreeMapChart from "./Charts/TreeMapChart"
import { Link } from "react-router-dom";
import { numberEditor, bookingPerioddateFormat } from "../../Helpers/Helpers"
import FundCategoriesDistribution from "../Categories/FundCategoriesDistribution";
import { data2 } from "./mockup";
import CoinsPieChartHandler from "./CoinsPieChartHandler";

const ChartsDashboard = ({ data }) => {
    const { darkmode } = useAuth()

    return (
        <div className="justify-content-center">
            {data &&
                data.map((fund, i) => (
                    <div className={styles.columnLayout} key={i}>
                        <div className={darkmode ? styles.cardTableDark : styles.cardTable}>
                            <div className="my-4">
                                <span><Link className={styles.cardLinks} to={`/main/dashboard/${fund.id}`}>Details</Link></span>
                                <span><Link className={styles.cardLinks} to={`/main/dashboard/sandbox/${fund.id}`}>Sandbox</Link></span>
                            </div>
                            <div className="my-1">
                                <h5 className={styles.mainTitle}>{fund.fundName}</h5>
                                <h5 className={styles.currentBookingPeriod}>Current Booking Period: {bookingPerioddateFormat(fund.currentBookingPeriod)}</h5>
                            </div>
                            <div className={darkmode ? styles.reportsDark : styles.reports}>
                                <div className={styles.headerData}><h5 className={styles.headerTitle}>HWM</h5><h4>{ numberEditor(fund.shareValueHWM) }</h4></div>
                                <div className={styles.headerData}><h5 className={styles.headerTitle}>DAILY NAV</h5><h4>{ fund.dailyNavs.length > 0 ? numberEditor(fund.dailyNavs[fund.dailyNavs.length - 1].nav) : "---" }</h4></div>
                                <div className={styles.headerData}><h5 className={styles.headerTitle}>PREV NAV</h5><h4>{ fund.nav ? numberEditor(fund.nav.nav) : "---" }</h4></div>
                            </div>
                            {/* <h5 className={`${styles.chartTitle} mb-5`}>NAV Distribution</h5> */}
                            <NavCharts dailyNavs={[...fund.dailyNavs].reverse()} />
                            {/* <h5 className={`${styles.chartTitle} mb-5`}>Layers Distribution</h5> */}
                            {/* <LayersChart layers={fund.layers} /> */}
                            <TreeMapChart holdings={fund.holdings} className="my-5" />
                        </div>
                            {/* <h5 className={`${styles.chartTitle} mb-5`}>Coin Distribution</h5> */}
                        <div className={`${darkmode ? styles.cardTableDark : styles.cardTable} px-3`}>
                            <CoinsPieChartHandler holdings={fund.holdings} />
                        </div>
                        <div className={darkmode ? styles.cardTableDark : styles.cardTable}>
                            <h5 className={styles.chartTitle}>Category Distribution</h5>
                            <FundCategoriesDistribution fundId={fund.id} fund={fund} /> 
                        </div>
                    </div>
                ))
            }
        </div>
    );
};
export default ChartsDashboard;

import useFunds from "../../query-hooks/fund-query-hooks/useFund";
import styles from "./MarketDepthPage.module.scss";
import {useAuth} from "../../Hooks/useAuth";
import {Col, Row} from "react-bootstrap";
import {Spinner} from "../../Helpers/Helpers";
import MarketDepthTable from "./MarketDepthTable";
import {MarketDepthStatusWidget} from "./MarketDepthStatusWidget";
import {useLocalStorage} from "../../Hooks/useLocalStorage";

export default function MarketDepthPage() {
  const {darkmode} = useAuth();
  const funds = useFunds();
  const [fundId, setFundId] = useLocalStorage("market_depth_fund_id", "null");

  if (funds.isLoading) {
    return <Spinner/>;
  }
  if (funds.isSuccess) {
    return (
      <>
        <select
          className={darkmode ? styles.selectInputDark : styles.selectInput}
          value={fundId}
          onChange={(evt) => setFundId(evt.target.value)}
        >
          <option key={"empty"} value={"null"}>Select a fund to see market depth</option>
          {funds.data.items.map((fund: any) => (
            <option key={fund.id} value={fund.id}>
              {fund.fundName}
            </option>
          ))}
        </select>

        {
          // fund id not selected
        }
        {fundId === "null" && <Row>
          <Col sm={12} className={"text-center mt-5"}>
            <h5>Select a fund to view market depth</h5>
          </Col>
        </Row>}

        {
          // fund id selected
        }
        {fundId !== "null" && (<><MarketDepthStatusWidget></MarketDepthStatusWidget>
            <Row className={"mt-4"}>
              <Col sm={12}>
                <MarketDepthTable fundId={fundId}/>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
};

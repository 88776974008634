import React, { useState } from 'react'
import { useBenchmarkCandles} from '../../../query-hooks/benchmark-query-hooks/useBenchmark';
import EmptyMessageComponent from "../../../Components/EmptyMessageComponent"
import { Table, Row, Col } from 'react-bootstrap';
import styles from "./AllBenchmarks.module.scss"
import ImportCandlesModal from './ImportCandlesModal';
import BenchmarkDataCandlesComponent from "./BenchmarkDataCandlesComponent.js"
import AddCandleForm from './AddCandleForm.js';
import {BenchmarkImportButtons} from "./BenchmarkImportButtons";

const BenchmarkCandlesData = ({ displayBenchmarkCandles }) => {
    const today = new Date()
    const benchmarkCandles = useBenchmarkCandles(displayBenchmarkCandles.benchmarkId, "Monthly", "2020-01-01", today.toISOString().slice(0, 10))
    const [ importCandlesModal, setImportCandlesModal ] = useState(false)
    const [ addCandle, setAddCandle ] = useState(false)

    if (importCandlesModal) {
        return <ImportCandlesModal setImportCandlesModal={setImportCandlesModal} benchmarkId={displayBenchmarkCandles.benchmarkId} /> 
    }

    if (benchmarkCandles.isSuccess && benchmarkCandles.data.length < 1) {
        return (
            <>
                <BenchmarkImportButtons benchmarkId={displayBenchmarkCandles.benchmarkId} setImportCandlesModal={setImportCandlesModal} />
                <EmptyMessageComponent message="No data stored" />
            </>
        )
    }

    if (benchmarkCandles.isSuccess) {
        return (
            <>
                <Row className="justify-content-center text-center">
                    <Col lg={6} md={12}>
                        <h3 className={styles.mainTitleDark}>{ displayBenchmarkCandles.name }</h3>
                        <BenchmarkImportButtons benchmarkId={displayBenchmarkCandles.benchmarkId} setImportCandlesModal={setImportCandlesModal} />
                    </Col>
                </Row>
                <Row className={`${styles.scrollableTable} justify-content-center mt-5`}>
                    <Col xl={8}>
                        <Table borderless={true}>
                            <thead className={styles.stickyHeader}>
                            <tr className={styles.theader}>
                                <th className='text-start'>Date Close</th>
                                <th>Price</th>
                                <th>Adjusted price</th>
                                <th>
                                    <button onClick={() => setAddCandle(true)} className={styles.editButton}>+ Add
                                    </button>
                                </th>
                                <th></th>
                            </tr>
                            </thead>
                            { addCandle && <AddCandleForm setAddCandle={setAddCandle} benchmarkId={displayBenchmarkCandles.benchmarkId} /> }
                            { benchmarkCandles.data.map(benchmark => 
                                <tbody style={{border:"solid 1px #6D9E93", borderRadius:"15px"}} key={benchmark.dateOpen}>
                                    <BenchmarkDataCandlesComponent benchmark={benchmark} />
                                </tbody> 
                            )}
                        </Table>
                    </Col>
                </Row>
            </>
        )
    }
}
export default BenchmarkCandlesData;
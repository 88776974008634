import React, { useState, useEffect } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import "./BenchmarkChart.css"
import { Row, Col } from "react-bootstrap"
import styles from "../BenchmarkDashboard.module.scss"
import { IoIosDownload } from "react-icons/io";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 4,
    layout: { padding: 10 },
    plugins: {
        datalabels: { display: false },
        legend: {
            position: "bottom",
            align: "center",
            labels: {
                font: { size: 20, family: "LarkenLight" },
                padding: 20
            },
        },
        tooltip: {
            callbacks: {
                label: function(context) {
                    let label = context.dataset.label 
                    if (label) {
                        label = `${label}: `;
                    }
                    if (context.parsed.y !== null) {
                        label = `${label} ${(context.parsed.y * 100).toFixed(2)}%`;
                    }
                    return label;
                }
            }
        },
    },
    scales: {
        y: {
            ticks: {
                callback: function(val, index) {
                    if (!(index % 2)) {
                        if (val > 0) {
                            return `+${Math.round(val * 100)}%`
                        }
                        return `${Math.round(val * 100)}%`;
                    }
                },
                autoSkip: false, 
                font: { family: "LarkenMedium", size: 20 }, 
                padding: 10
            },
            grid: { display: true },
            border: { color: "black", width: 3 }, // Axis color and line weight
            offset: true, // Separation between chart line and axis line
        },
        x: {
            ticks: { 
                callback: function(val, index, values) {
                    const valuesLength = values.length;
                    const dateString = this.getLabelForValue(index) // Date in a string format YYYY-MM-DD
                    const [, month] = dateString.split('-').map(Number); // Get month number

                    // If range is larger than 12 months, Do not display before last date or second date to avoid date overlapping with first or last
                    if (valuesLength >= 40 && dateString.includes('6-2024')) {
                        return ''
                    }

                    // Display ticks for the first, last, and predefined months (June and December)
                    if (index === 0 || index === valuesLength - 1 || [6, 12].some(x => x === month)) {
                        return dateString.replace(/-/g, '/');
                    }
                },
                autoSkip: true,
                font: { family: "LarkenMedium", size: 20 }, 
                padding: 20,
                maxRotation: 0,
                minRotation: 0,
            },
            grid: { display: false },
            border: { color: "black", width: 3 }, // Axis color and line weight
            offset: true, // Separation between chart line and axis line
        }
    }
};

const colors = ["#0E3531", "#669771", "#6D9E93", "#A4F34F"] // Set of colors for chart lines

// Function to format date for x label style
const dateFormatter = dateString => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return day + '-' + month + '-' + year
}

const quotesStringHandler = (quotes, fundPercentageSplit) => {  // Function to concat quotes with percentages
    const quoteStrings = quotes.map(quote => `- ${quote.percentage - fundPercentageSplit}% ${quote.symbol}`)
    return quoteStrings.join("")
}

export function BenchmarkChart({ graphData, hookedData, fund, transformedFundName, linechartRef, handleDownloadImage, handleDownloadSVG }) {
    const [ graphLabels, setGraphLabels ] = useState([])
    useEffect(() => {
        const fundData = hookedData[fund.id]
        const getMonthlyReturnsDates = arrayData => arrayData.map((element, i, array) => array[i] && ({date: element.date})).filter(val => typeof val !== 'undefined')
        const fundMonthlyReturns = getMonthlyReturnsDates(fundData)
        // Set dates for graph labels
        setGraphLabels(fundMonthlyReturns.map(x => x.date))
    }, [ hookedData ])

    const data = {
        labels: graphLabels && [...graphLabels.map(label => dateFormatter(label) )], // Added First Date value as empty to start from Zero
        datasets: graphData.map((data, i) => ({ 
            label: `${data.fundPercentage}% ${transformedFundName} ${i === 0 ? quotesStringHandler(data.quotes, data.fundPercentageSplit) : ""}`, 
            data: [0, ...data.datasets], // Added First data value as Zero for all data set
            borderColor: colors[i], 
            backgroundColor: colors[i],
        }))
    };

    return (
        <>
            <div className={styles.dropdown} title="Download Chart Image">
                <IoIosDownload className={styles.downloadIcon} />
                <div className={styles.dropdownContent}>
                    <p onClick={() => handleDownloadImage("linechart", linechartRef)}>Download as PNG</p>
                    <p onClick={() => handleDownloadSVG("linechart", linechartRef)}>Download as SVG</p>
                </div>
            </div>
            <span className={styles.chartTitle} style={{fontFamily:"LarkenMedium"}}>Portfolio Comparison</span>
            <Row>
                <Col xl={12} className={styles.chartsContainer} ref={linechartRef}>
                    <Line options={options} data={data} height={500} />
                </Col>
                <p className={styles.portfolioComparisonText}>*For this comparison, we used SPDR S&P 500 ETF Trust (SPY) and Vanguard Total International Bond Index Fund ETF (BNDX). The iShares 7-10 Year Treasury Bond ETF (IEF) has been used as a proxy for the risk-free rate of return to calculate the Sharpe Ratio. Past performance is no guarantee of future results.</p>
            </Row>
        </>
    ) 
}


import React, { useState } from "react";
import { SlArrowDown, SlArrowRight } from "react-icons/sl";
import { percentageEditor } from "../../../../Helpers/Helpers";
import { CategoryTree } from "./CategoryTree";
import styles from "../AddFundCategoryForm.module.scss";
import { useFieldArray, useFormContext } from "react-hook-form";

export function GroupTree({
  fundId,
  groupIndex,
}: {
  fundId: string;
  groupIndex: number;
}) {
  const [isOpen, setIsOpen] = useState(true);

  const methods = useFormContext();
  const { getValues, control } = methods;

  const { fields: categories } = useFieldArray({
    name: `groups.${groupIndex}.categories`,
    control,
  });

  return (
    <div className={"w-full"}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`w-full d-flex flex-row text-sm font-semibold py-1 ${styles.mediumFont}`}
        style={{ justifyContent: "space-between", color: "#4D7E73" }}
      >
        <span className="flex items-center">
          {isOpen ? <SlArrowDown /> : <SlArrowRight />}
          {getValues(`groups.${groupIndex}.name`)}
        </span>
        <span
          style={{ marginRight: "2.6em" }}
          className="text-muted-foreground"
        >
          {percentageEditor(getValues(`groups.${groupIndex}.weight`) * 100)}
        </span>
      </div>
      {isOpen && (
        <div style={{ paddingLeft: "1em" }}>
          {categories.map((_, index) => (
              <CategoryTree
                key={`category-${index}`}
                fundId={fundId}
                groupIndex={groupIndex}
                categoryIndex={index}
              />
          ))}
        </div>
      )}
    </div>
  );
}
